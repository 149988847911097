.inputdemoBasicUsage .md-datepicker-button {
    width: 36px;
}

.layout-padding {
    overflow-x: hidden;
}

.container {
    max-width: 1024px;
    margin: 50px auto;
}

md-content.md-default-theme {
    background-color: #eee;
}

md-card {
    background-color: #fff;
}
md-card h2:first-of-type {
    margin-top: 0;
}

md-toolbar .md-button.md-default-theme {
    border-radius: 99%;
}

h2 {
    font-weight: 400;
}

.md-toolbar-tools-bottom {
    font-size: small;
}
.md-toolbar-tools-bottom :last-child {
    opacity: 0.8;
}

md-toolbar:not(.md-hue-1),
.md-fab {
    fill: #fff;
}

#chartsWrapper {
    clear: left;
}

.header .flag-icon {
	font-size: 48px;
}

.header img, .header .flag-icon {
	margin-right: 10px;
	cursor: pointer;
}
.header img {
    height:48px;
}
.header {
	background: #f6f6f6 !important;
}

.header .links, .header .links md-icon {
    color: #222;
    font-weight: normal;
}

.header .links a{
    color: rgb(63,81,181);
}

.footer {
    background: #e0e0e0 !important;
}



.footer .copyright {
    color: #777;
    font-weight: normal;
}

.header .md-toolbar-tools .md-button.md-icon-button md-icon, .header h2 {
	color: #222;
}

md-sidemenu-button md-icon {
    margin: 0;
}

.md-sidemenu .md-button.md-active {
    background-color: rgba(121, 134, 203, .2);
}

.md-sidemenu .md-sidemenu-toggle.md-button.md-active {
    background-color: #e0e0e0;
}

.md-sidemenu-wrapper.md-sidemenu-wrapper-icons .subsubmenubutton .md-button {
    padding-left: 90px;
}

.dashboard-card {
	background-color: aliceblue;
}
.dashboardContent {
    padding: 20px;
}
.cardNumbers {
    color: white;
    font-weight: 700;
    font-size: 20px;
    padding-top: 25px;
}
.cardMedia, .cardMediaNoPercentage {
    color: white;
    border-radius: 4px;
    background: #9bbb34;
    text-align: center;
}
.cardNumbersPercentage, .cardNumbersNoPercentage {
    font-weight: 700;
    font-size: 26px;
}
.cardNumbersNoPercentage {
    max-width: 50%;
}
.cardMediaNoPercentage {
    display: flex;
    align-items: center;
    justify-content: center;
}

.dashboard-card h1 {
	font-size: 64px;
	color: #444;
}

.material-icons.md-48 {
	font-size: 48px;
	height: 48px;
	width: 48px;
	min-height: 48px;
	min-width: 48px;
}
.panel-body {
    padding: 50px;
}
.paddingContainer, .dividerPadding{
    padding: 15px;
}
.surveysContainer, .forecastColumnWrapper {
    margin-top: 8px;
}
.forecastQuestions {
    padding: 15px 0 15px 0;
}
.forecastQuestion {
    padding: 15px;
    min-width: 200px;
}
.forecastScoreLabels {
    width: 85%;
}
.forecastScoreLabels label {
    font-size: 18px;
}

/*#surveysTable th {*/
    /*font-size: 18px;*/
    /*cursor: pointer;*/
/*}*/
/*#surveysTable td {*/
    /*font-size: 16px;*/
/*}*/
#surveysTable {
	display: block;
    overflow-x: scroll;
}

#surveysTable .boldCell {
    font-weight:700;
}

#questionsTable {
    display: block;
    overflow-x: scroll;
}

#questionsTable thead th {
    vertical-align: top;
}

#questionsTable th.sortable .sort-indicator {
    display: inline-flex;
}

.st-sort-ascent:before {
    content: '\25B2';
}

.st-sort-descent:before {
    content: '\25BC';
}

.lr-drop-target-before{
    border-left: 2px solid orange;
}

.lr-drop-target-after{
    border-right: 2px solid orange;
}
#thead th.md-column {
    cursor: pointer;
}


.optionIcons {
    margin: 0 2px;
    cursor: pointer;
}
.crossWithIcon {
    margin-top: 6px;
}
.pagination {
    justify-content: flex-end;
}
#surveysTable td {
    padding: .5rem;
    vertical-align: middle;
}

.csvSpinner {
    display: inline-block;
    margin-left: 5px;
    margin-top: 3px;
}
.csvCrossInput {
    max-width: 35%;
    margin: 5px 0;
}
#spinnerOverlay {
    position: absolute;
    top: 9%;
    left: 50%;
}
.minWidthBtn {
    min-width: 90px;
}
.chart-not-rendered {
    opacity: 0.1;
}
.chart-rendered {
    opacity: 1;
}
#surveysTable .csvSpinner.md-mode-indeterminate svg {
    margin-top: 3px;
}
.optionsTD {
    min-width: 175px;
    text-align: center;
}
.autoMagicallySpan {
    font-weight: 700;
    color: green;
}
.autoMagicallyDiv {
    padding: 10px;
    text-align: center;
}
#surveysTable tr:hover {
    background: #eceeef;
}
.linkUrlDiv {
    padding: 10px;
}
.userContainer {
    padding: 30px;
}
.userInfoContainer {
    padding: 20px;
}
.searchUserInputDiv {
    padding: 20px;
}
.userProfileContainer, .userDataContainer {
    margin-top: 15px;
    padding: 10px;
}

.profileFAIcons {
    font-size: 1.6em;
    cursor: pointer;
}
.profileIcons {
    cursor: pointer;
    margin-right: 20px;
}
.profileFields, .profileFieldsNot {
    font-weight: 500;
}
.ticketsImg {
    cursor: pointer;
    width: 27px;
    height: 27px;
}
.pointsSvg {
    cursor: pointer;
    height: 27px;
    width: 27px;
}
.userAvatar {
    width: 110px;
    height: 110px;
    border-radius: 50%;
}
.genderIcon {
    display: block;
}

.profileFieldsNot {
    color: red;
}
.tooltipSpan {
    display: inline-block;
}
.infoTooltip {
    font-size:  14px;
}
.infoTooltip.top {
    margin-bottom: 50px;
}
.raffleContent, .raffleTableContent, .raffleDetails {
    padding: 30px;
}
.raffleTableContent {
    margin-top: 10px;
}
.raffleAlert {
    padding: 10px;
    font-weight: 700;
}
.dashAddEvent {
    margin-bottom: 30px;
    padding: 5px;
}
.cardProgress {
    width: 95%;
    margin-top: 10px;
}
.percentageBar {
    margin-top: 15px;
}

.question-container {
    position: relative;
}

.question-container md-card-content {
    padding-bottom: 60px;
}

.choice-container md-input-container {
    margin: 5px 0;
}

.choice-container {
    padding-top: 0;
    margin-bottom: 5px;
    border-radius: 8px;
    background-color: #F0F0F0;
    padding-bottom: 0;
}

.dailyQContent {
    padding: 20px;
}
.dailyQTable {
    max-width: 30%;
}
.downloadQodDiv {
    padding: 20px;
}
.downloadQodDiv a {
    padding: 10px;
    background: #0275d8;
    border-radius: 5px;
    color: white;
}
#surveysTable md-input-container .md-errors-spacer,
#addDivisionInput .md-errors-spacer,
#addRegionInput .md-errors-spacer{
    min-height: 0;
}
#addCountyInput .md-errors-spacer{
     min-height: 0;
 }
#addCityInput .md-errors-spacer{
    min-height: 0;
}
.history-chart-not-rendered {
    background: #ccc;
}
.history-chart-rendered {
    background: white;
}
.forecastSpinner {
    position: absolute;
    top: 50%;
    left: 50%;
}

.md-icon-button.error md-icon {
    color: #FF5252;
}

.md-icon-button.ok md-icon {
    color: #8BC34A;
}

md-sidenav.md-sidenav-right,
md-sidenav.md-sidenav-right.md-locked-open,
md-sidenav.md-sidenav-right.md-closed.md-locked-open-add-active {
    min-width: 500px !important;
    width: 60vw!important;
    max-width: 59%;
}
md-sidenav.md-sidenav-right {
    position: fixed;
}
.logicFooter {
    padding: 20px;
}
.skipRow {
    padding: 0 15px;
    max-width: 110vh;
}
.skipGroupsLabel {
    display: flex;
    align-items: center;
    justify-content: center;
}
.alignSkipButtons {
    display: flex;
    justify-content: center;
    align-items: center;
}
.paddingQodStats {
    padding: 20px 10px;
}
.noQodStatsP {
    font-weight: 700;
    color: red;
}
.saveQuest {
    padding: 20px;
}
.successMsgs {
    padding: 15px;
}

main {
    overflow-y: auto;
}
.chartContainer {
    width: 100%;
    height: 600px;
}

.survey-chart-container {
    height: 700px;
    width: 1800px;
    zoom: 0.75;
    margin: 0 auto;
}

.survey-chart-container-amcharts4{
    height: 500px;
    width: 1400px;
    margin: 0 auto;
}

.survey-chart-container-thai {
    height: 500px;
    width: 1500px;
    zoom: 0.75;
    margin: 0 auto;
}

.rcs-chart-container {
    height: 700px;
    width: 100%;
    margin: 0 auto;
}

.rcs-chart-legend {
    height: 200px;
}

.md-breadcrumb {
    font-size: 24px !important;
    font-weight: 400 !important;
}
.inputContainerRegions, .inputContainerCounties, .inputContainerZoneCities {
    margin-top: 0;
    max-width: 600px;
}
#addDivisionInput, #addRegionInput, #addCountyInput, #addCityInput {
    display: flex;
    align-items: center;
}
.regionsPageCard {
    padding: 0 20px;
}

#regionsTable tr, #divisionsTable tr, #countiesTable tr, #citiesTable tr {
    text-align: center;
}

@media (max-width: 700px) {
    #regionsTable tr td md-input-container,
    #divisionsTable tr td md-input-container {
        max-width: 25vh;
    }
    #countiesTable tr td md-input-container {
        max-width: 25vh;
    }
    #citiesTable tr td md-input-container {
        max-width: 25vh;
    }
}

@media (min-width: 701px) and (max-width: 999px) {
    #regionsTable tr td md-input-container,
    #divisionsTable tr td md-input-container {
        max-width: 50vh;
    }
    #countiesTable tr td md-input-container {
        max-width: 50vh;
    }
    #citiesTable tr td md-input-container {
        max-width: 50vh;
    }
}

@media (min-width: 1000px) {
    #regionsTable tr td md-input-container,
    #divisionsTable tr td md-input-container {
        max-width: 75vh;
    }
    #countiesTable tr td md-input-container {
        max-width: 75vh;
    }
    #citiesTable tr td md-input-container {
        max-width: 75vh;
    }
}
.surveyEditedWarning {
    font-weight: 500;
    font-size: 15px;
    color: red;
}
.saveSurveyError {
    font-weight: 700;
    color: red;
}
.saveSurveyOk {
    font-weight: 700;
    color: green;
}
.filteredUsersCountDiv {
    background: #fafafa;
	border-top: 1px solid #ccc;
	padding-left: 20px;
}
.validTicketsDiv {
    background: #fafafa;
    font-size: 20px;
    padding-left: 20px;
    padding-bottom: 7px;
    padding-top: 7px;
}
.raffleDrawsLeftDiv {
    font-size: 20px;
    padding-left: 20px;
    padding-bottom: 7px;
    padding-top: 7px;
}
.md-select-placeholder span{
	margin-top: -17px;
}
.filteredUsersCountSpan {
	font-weight: 500;
    font-size: 18px;
	color: #adc952;
}

.filteredUsersAndTicketsCountSpan {
    font-weight: 500;
    font-size: 20px;
    color: #adc952;
    padding-bottom: 7px;
    padding-top: 7px;
}

.filteredUsersCountTickets {
    background: #fafafa;
    border-top: 1px solid #ccc;
    padding-left: 20px;
    font-size: 20px;
    padding-bottom: 5px;
    padding-top: 5px;

}
.centeredCardTitle {
	text-align: center;
}
.border-bottom-class{
	border-bottom: 1px solid #ccc;
	margin-bottom: 10px;
}
.greenText {
	color: #86c943;
}
.alertText {
	color: #8a8027
}
.redText {
	color: #ca1010;
}
.correlateGroupWrapper{
    padding: 10px;
}
.correlationChoiceRowWrapper {
	padding-left: 15px;
}
.correlationGroupDeleteItem {
	position: absolute;
	right: 20px;
	top: 8px;
}
.correlationCloseIcon {
	font-size: 40px;
}
.multipleOptionDynamicMarginTop {
    margin-top: 6px;
}
.dividerMarginTop {
    margin-top: 20px;
}


md-menu-content { max-height: 50vh; }

md-list.md-dense.md-dense-extra md-list-item {
    min-height: 32px;
    height: 32px;
}

.ellipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.word-cloud-legend-appearances{
    background: rgba(0,0,0,0.62);
    border-radius: 30%;
    padding: 2px 10px;
    color: white
}

.cropArea {
    background: #eee;
    overflow: hidden;
    width: 600px;
    height: 600px;
    border: 5px dashed #DDD;
    text-align: center;
    margin: 20px 0;
}

.cropArea span {
    font-weight: bold;
    font-size: 20px;
    line-height: 80px;
}


.bordered-result {
    padding: 10px;
    border: 1px solid grey;
    border-radius: 5px;
    color:grey;
    font-size: 28px;
    font-weight: bold;
    width: 120px;
    text-align: center;
}

.bordered-result.lower {
    border-color: darkred;
    color: darkred;
}
.bordered-result.higher {
    border-color: green;
    color: green;
}
.bordered-result.similar {
    border-color: grey;
    color: grey;
}

md-toolbar .overlay {
    position: absolute;
    top: 0;
    left: 80px;
    background: #f0f8ff6b;
    height: 100%;
}
.importance-prefix {
    position: absolute;
    left: 0;
    color: #888888;
    font-size: 18px;
    background: #fafafa;
    height: 100%;
    width: 80px;
    line-height: 64px;
    text-align: center;
}

md-whiteframe {
    margin: 8px;
    height: 64px;
    background-color: #0F5193;
    color: #fafafa;
    font-size: 16px;
    font-weight: bold;
}

md-whiteframe .box {
    background: #f6f6f6;
    color: #133f72;
    font-size: 20px;
}

.logoml {
    background: rgba(255, 255, 255, 0.8);
    padding: 0 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.logoml .flag-icon {
    font-size: 48px;
    vertical-align: middle;
}

header .title {
    font-family: 'Chakra Petch', sans-serif;
    color: rgba(255, 255, 255, 0.87);
    font-size: 50px;
    font-weight: 500;
    margin-top: 20px;
    text-shadow: 1px 1px 2px #119dac;
}

header .menu {
    position: absolute;
    bottom: 0;
    left: 10%;
}

header .menu a, header .menu a:visited {
    color: white;
    text-decoration: none;
    font-size: 20px;
    text-transform: uppercase;
    margin-right: 40px;
}
header .menu a:hover {
    text-decoration: underline;
}
header .parent {
    position: relative;
}

md-icon.info-tip {
    width: 60px;
    height: 60px;
    font-size: 60px;
    margin-right: 20px;
    line-height: 45px;
    color: #ddd;
}

.ui-select-highlight {
    font-weight: bold;
}

.ui-select-offscreen {
    clip: rect(0 0 0 0) !important;
    width: 1px !important;
    height: 1px !important;
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden !important;
    position: absolute !important;
    outline: 0 !important;
    left: 0px !important;
    top: 0px !important;
}

.ui-select-choices-row:hover {
    background-color: #f5f5f5;
}

/* Select2 theme */

/* Mark invalid Select2 */
.ng-dirty.ng-invalid > a.select2-choice {
    border-color: #D44950;
}

.select2-result-single {
    padding-left: 0;
}

.select2-locked > .select2-search-choice-close{
    display:none;
}

.select-locked > .ui-select-match-close{
    display:none;
}

body > .select2-container.open {
    z-index: 9999; /* The z-index Select2 applies to the select2-drop */
}

/* Handle up direction Select2 */
.ui-select-container[theme="select2"].direction-up .ui-select-match,
.ui-select-container.select2.direction-up .ui-select-match {
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.ui-select-container[theme="select2"].direction-up .ui-select-dropdown,
.ui-select-container.select2.direction-up .ui-select-dropdown {
    border-radius: 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    border-top-width: 1px;
    border-top-style: solid;

    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.25);

    margin-top: -4px;
}
.ui-select-container[theme="select2"].direction-up .ui-select-dropdown .select2-search,
.ui-select-container.select2.direction-up .ui-select-dropdown .select2-search {
    margin-top: 4px;
}
.ui-select-container[theme="select2"].direction-up.select2-dropdown-open .ui-select-match,
.ui-select-container.select2.direction-up.select2-dropdown-open .ui-select-match {
    border-bottom-color: #5897fb;
}

.ui-select-container[theme="select2"] .ui-select-dropdown .ui-select-search-hidden,
.ui-select-container[theme="select2"] .ui-select-dropdown .ui-select-search-hidden input{
    opacity: 0;
    height: 0;
    min-height: 0;
    padding: 0;
    margin: 0;
    border:0;
}

.btn-default-focus {
    color: #333;
    background-color: #EBEBEB;
    border-color: #ADADAD;
    text-decoration: none;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.md-chips{
    box-shadow: 0 0px !important;
    outline: none;
}

.chips-stats-custom {
    float: left;
    width: 25%;
    box-sizing: border-box;
    padding: 0 8px;
}

.chips-stats-custom .md-item-text{
    padding: 8px;
    background: #f0f0f0;
    border-radius: 7px;
}

.chips-stats-custom .md-item-text h3, .chips-stats-custom .md-item-text p {
    padding: 0;
    margin: 0;
}

#multiValueSelect[multiple=true] .md-select-value span:first-child {
    visibility: collapse;
}

#multiValueSelect[multiple=true] .md-select-value .md-text {
    display: inline-block !important;
    visibility: visible;
}

#multiValueSelect[multiple=true] .md-select-value .md-text:first-child:before {
    content: '\005B';
    margin: 0;
}

#multiValueSelect[multiple=true] .md-select-value .md-text:last-child:after {
    content: '\005D';
    margin: 0;
}

#multiValueSelect[multiple=true] .md-select-value .md-text:not(:last-child):after {
    content: '\01C0';
    margin: 0;
}

.blue-background {
    background: #3a5fec7a;
    padding: 15px;
    border: 5px solid white;
    border-radius: 20px;
}

.green-background {
    background: #3aec4175;
    padding: 15px;
    border: 5px solid white;
    border-radius: 20px;
}

.grey-background {
    background: #e8e5e5;
    padding: 15px;
    border: 5px solid white;
    border-radius: 20px;
}

/* ML header */

header {
    position: relative;
    background-image: url("/images/ml2_header.jpg");
    background-size: cover;
}

@media (max-width: 575px) {
    header {
        background-image: url("/images/ml2_header_mobile.jpg");
    }
}

header .header__logo {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 0px;
    padding-bottom: 0px;
}

@media (min-width: 576px) {
    header .header__logo {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    header .header__logo {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    header .header__logo {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    header .header__logo {
        max-width: 1140px;
    }
}

@media (min-width: 1600px) {
    header .header__logo {
        max-width: 1400px;
    }
}

@media (min-width: 2000px) {
    header .header__logo {
        max-width: 1600px;
    }
}

header .header__logo a img {
    height: auto;
    width: 145px;
}

header .header__divider {
    background-color: #ffffff;
    height: 1px;
    opacity: .2;
}

header .header__nav {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    align-items: center;
}

@media (min-width: 576px) {
    header .header__nav {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    header .header__nav {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    header .header__nav {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    header .header__nav {
        max-width: 1140px;
    }
}

@media (min-width: 1600px) {
    header .header__nav {
        max-width: 1400px;
    }
}

@media (min-width: 2000px) {
    header .header__nav {
        max-width: 1600px;
    }
}

header .header__nav nav.main-nav {
    display: flex;
    width: 100%;
    visibility: visible;
    align-items: center;
    background: transparent;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

header .header__nav nav.main-nav .nav {
    position: relative;
    display: block;
    width: 100%;
    padding: 0px;
    margin: 0px;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

header .header__nav nav.main-nav .nav .nav__item {
    color: #ffffff;
    text-align: center;
    padding: 10px 25px 10px 0;
    display: inline-block;
    position: relative;
}

header .header__nav nav.main-nav .nav .nav__item + .nav__item {
    padding-left: 25px;
}

header .header__nav nav.main-nav .nav .nav__item:hover > .dropdown {
    display: block;
    opacity: 1;
}

header .header__nav nav.main-nav .nav .nav__item.nav__item--active a {
    color: #FC2222;
    font-weight: 600;
}

header .header__nav nav.main-nav .nav .nav__item a {
    position: relative;
    display: inline-block;
    text-decoration: none;
    font-size: 1em;
    line-height: 1em;
    color: #ffffff;
    font-weight: 500;
}
li.nav__item:last-child { float:right; }

header .header__nav nav.main-nav .nav .nav__item a:hover, header .header__nav nav.main-nav .nav .nav__item a:focus {
    color: #FC2222;
}

header .header__nav nav.main-nav .dropdown {
    position: absolute;
    display: none;
    opacity: 0;
    visibility: visible;
    background-color: #070E1E;
    padding: 15px;
    min-width: 100%;
    text-align: center;
    list-style: none;
    right: 20px;
    top: 35px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(216, 216, 216, 0.5);
    -ms-box-shadow: 0px 0px 15px 0px rgba(216, 216, 216, 0.5);
    box-shadow: 0px 0px 15px 0px rgba(216, 216, 216, 0.5);
}

header .header__nav nav.main-nav .dropdown::before {
    content: "";
    border-style: solid;
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent #070E1E transparent;
    position: absolute;
    right: 10px;
    top: -5px;
}

header .header__nav nav.main-nav .dropdown .dropdown__item {
    white-space: nowrap;
}

header .header__nav nav.main-nav .dropdown .dropdown__item + .dropdown__item {
    padding-top: 10px;
}

header .header__nav nav.main-nav.main-nav--mobile {
    display: none;
}

@media (max-width: 991px) {
    header .header__nav nav.main-nav.main-nav--mobile {
        display: flex;
    }
}

@media (max-width: 991px) {
    header .header__nav nav.main-nav:not(.main-nav--mobile) {
        position: fixed;
        top: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.3);
        opacity: 0;
        visibility: hidden;
        height: 100%;
        width: 100%;
        z-index: 90;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
    }

    header .header__nav nav.main-nav:not(.main-nav--mobile) .nav {
        list-style: none;
        position: fixed;
        display: block;
        background-color: #ffffff;
        top: 0;
        right: 0;
        width: calc(100% - 250px);
        left: 100%;
        height: 100%;
        max-width: 100%;
        padding: 50px 0px 0px 0px;
        margin: 0px;
        -webkit-box-shadow: 2px 0px 20px 0px rgba(0, 0, 0, 0.3);
        -ms-box-shadow: 2px 0px 20px 0px rgba(0, 0, 0, 0.3);
        box-shadow: 2px 0px 20px 0px rgba(0, 0, 0, 0.3);
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
    }
}

@media (max-width: 991px) and (max-width: 767px) {
    header .header__nav nav.main-nav:not(.main-nav--mobile) .nav {
        width: calc(100% - 200px);
    }
}

@media (max-width: 991px) and (max-width: 575px) {
    header .header__nav nav.main-nav:not(.main-nav--mobile) .nav {
        width: calc(100% - 60px);
    }
}

@media (max-width: 991px) {
    header .header__nav nav.main-nav:not(.main-nav--mobile) .nav .nav__item {
        text-align: center;
        padding: 10px 15px;
        width: 100%;
    }

    header .header__nav nav.main-nav:not(.main-nav--mobile) .nav .nav__item.nav__item--active a {
        color: #FC2222;
    }

    header .header__nav nav.main-nav:not(.main-nav--mobile) .nav .nav__item.nav__item--active a::before {
        width: 100%;
    }

    header .header__nav nav.main-nav:not(.main-nav--mobile) .nav .nav__item a {
        position: relative;
        display: inline-block;
        text-decoration: none;
        text-transform: uppercase;
        color: #1e1e22;
        font-weight: 600;
    }

    header .header__nav nav.main-nav:not(.main-nav--mobile).open {
        opacity: 1;
        visibility: visible;
    }

    header .header__nav nav.main-nav:not(.main-nav--mobile).open .nav {
        left: 250px;
    }
}

@media (max-width: 991px) and (max-width: 767px) {
    header .header__nav nav.main-nav:not(.main-nav--mobile).open .nav {
        left: 200px;
    }
}

@media (max-width: 991px) and (max-width: 575px) {
    header .header__nav nav.main-nav:not(.main-nav--mobile).open .nav {
        left: 60px;
    }
}

header .header__nav .burger-menu {
    width: 35px;
    height: 24px;
    position: relative;
    z-index: 100;
    display: none;
    cursor: pointer;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

@media (max-width: 991px) {
    header .header__nav .burger-menu {
        display: flex;
    }
}

header .header__nav .burger-menu span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: #ffffff;
    left: 0;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
}

header .header__nav .burger-menu span:nth-child(1) {
    top: 0px;
}

header .header__nav .burger-menu span:nth-child(2), header .header__nav .burger-menu span:nth-child(3) {
    top: 10px;
}

header .header__nav .burger-menu span:nth-child(4) {
    top: 20px;
}

header .header__nav .burger-menu.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
}

header .header__nav .burger-menu.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

header .header__nav .burger-menu.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

header .header__nav .burger-menu.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
}

/* ML footer */

footer {
    background-color: #070E1E;
    margin-top: auto;
}

footer .footer_logo {
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    footer .footer_logo {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    footer .footer_logo {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    footer .footer_logo {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    footer .footer_logo {
        max-width: 1140px;
    }
}

@media (min-width: 1600px) {
    footer .footer_logo {
        max-width: 1400px;
    }
}

@media (min-width: 2000px) {
    footer .footer_logo {
        max-width: 1600px;
    }
}

footer .footer_logo .footer_logo__image {
    max-width: 100%;
    width: 200px;
    height: auto;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.background-lightsteelblue {
    background-color: lightsteelblue;
}

.background-lightblue {
    background-color: lightblue;
}

.background-white {
    background-color: white;
}

.background-lightgreen {
    background-color: #daf7eb;
}

.background-lightpink {
    background-color: #f3d3d9;
}

.background-lightpink-2 {
    background-color: #f6e4de;
}

.background-lightpink-on-child-inputs > input{
    background-color: #f6e4de;
}

.input-red-warning > input {
    border-color: red;
    border-bottom-width: 2px;
}

.input-red-warning > input::placeholder {
    color: red;
}

.input-red-warning > label {
    color: red;
}

.input-gray-empty > input {
    border-color: #a4a5af;
    border-bottom-width: 2px;
}

.input-gray-empty > input::placeholder {
    color: #a4a5af;
}

.background-white-on-child-inputs > input {
    background-color: white;
}

.custom-table-1 {
    border-top: 0px solid lightgray;
    border-bottom: 1px solid lightgray;
    border-right: 1px solid lightgray;
    border-left: 1px solid lightgray;
    padding: 10px 20px;
}

.custom-table-1:first-of-type {
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    border-right: 1px solid lightgray;
    border-left: 1px solid lightgray;
    padding: 10px 20px;
}

.custom-table-1:last-of-type {
    border-top: 0px solid lightgray;
    border-bottom: 1px solid lightgray;
    border-right: 1px solid lightgray;
    border-left: 1px solid lightgray;
    padding: 10px 20px;
}

.custom-input-1 {
    padding-left: 15px;
    padding-right: 15px;
}

.no-margin {
    margin: 0;
}

.no-padding {
    padding: 0;
}

.grayed-out-text {
    opacity: 0.5;
}

/* Snackbars (Toaster) CSS */
.toast-top-right {
    top: 100px;
    right: 35px;
}

.toast-close-button {
    opacity: 1;
}

.toast-message a {
    color: #000099;
}

#toast-container>div {
    padding: 15px;
    opacity: 1;
    min-width: 480px;
    margin: 0 0 10px;
}

#toast-container>.toast-success {
    background-image: none!important;
    background-color: #fff;
    border-left: 3px solid lightgreen;
}

#toast-container>.toast-warning {
    background-image: none!important;
    background-color: #fff;
    border-left: 3px solid darkorange;
}

#toast-container>.toast-error {
    background-image: none!important;
    background-color: #fff;
    border-left: 3px solid orangered;
}

#topicsTable .topic-container {
    margin: 28px 0;
}

#topicsTable .topic-actions-container {
    margin: 33px 0;
}

#topicsTable .topic-translation-lang-container {
    margin-bottom: 3px;
    margin-right: 10px;
}

#topicsTable tr td md-input-container {
    margin: 0;
}

#topicsTable tr td md-input-container .md-errors-spacer {
    min-height: 5px;
}

#topicsTable md-input-container .md-input[disabled] {
    background-image: none;
    border-bottom-color: rgba(0,0,0,0.38);
}

#rafflePrizesTable td {
    padding: .1rem;
}

#editPrizeCard md-input-container {
    margin: 0;
}

.question-choice-icons {
    display: flex;
    justify-content: center;
}

.question-choice-icons button.custom-button-icon {
    display: inline;
    width: 40px;
    padding: 0;
    margin: 0 2%;
}

.questionaire__reports__container__content__table {
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.questionaire__reports__container__content__table .questionaire__reports__container__content__table__row {
    justify-content: space-between;
    display: flex;
    font-size: 16px;
    line-height: 55px;
    padding: 0 50px;
}

@media (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 1200px) and (max-width: 1600px) {
    .questionaire__reports__container__content__table .questionaire__reports__container__content__table__row {
        font-size: 14px;
        padding: 0 40px;
    }
}

@media (max-width: 767px) {
    .questionaire__reports__container__content__table .questionaire__reports__container__content__table__row {
        font-size: 12px;
        padding: 0 15px;
    }
}

.questionaire__reports__container__content__table .questionaire__reports__container__content__table__row:not(.questionaire__reports__container__content__table__row--head) > span:last-of-type {
    font-size: 18px;
    font-weight: 500;
}

@media (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 1200px) and (max-width: 1600px) {
    .questionaire__reports__container__content__table .questionaire__reports__container__content__table__row:not(.questionaire__reports__container__content__table__row--head) > span:last-of-type {
        font-size: 16px;
    }
}

@media (max-width: 767px) {
    .questionaire__reports__container__content__table .questionaire__reports__container__content__table__row:not(.questionaire__reports__container__content__table__row--head) > span:last-of-type {
        font-size: 14px;
    }
}

.questionaire__reports__container__content__table .questionaire__reports__container__content__table__row:nth-child(2n) {
    background-color: #f7f7f7;
}

.questionaire__reports__container__content__table .questionaire__reports__container__content__table__row.questionaire__reports__container__content__table__row--head {
    text-transform: uppercase;
    color: #838894;
    font-weight: 500;
    line-height: 60px;
}

@media (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 1200px) and (max-width: 1600px) {
    .questionaire__reports__container__content__table .questionaire__reports__container__content__table__row.questionaire__reports__container__content__table__row--head {
        line-height: 50px;
    }
}

@media (max-width: 767px) {
    .questionaire__reports__container__content__table .questionaire__reports__container__content__table__row.questionaire__reports__container__content__table__row--head {
        font-size: 14px;
    }
}
