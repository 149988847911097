

/* Typography */

#qdStyled h1,
#qdStyled h2,
#qdStyled h3,
#qdStyled h4 {
	font-family: 'Catamaran', 'Open Sans', "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-weight: 700;
	line-height: 1.2;
}


.section-heading {
	margin-top: 0;
	font-size: 2.25em;
	text-transform: uppercase;
	color: #000;
}

/* Card */

.qod-image {
	display: block;
	position: relative;
	width: 952px;
	height: 952px;
	background: #eee;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
#iz-card-ro {
	background-image: url(/images/background.png);
}
#iz-card-al {
	background-image: url(/images/background-al.png);
}
#iz-card-rs {
	background-image: url(/images/background-rs.png);
}
#iz-card-co\.id {
	background-image: url(/images/background-id.png);
}
#iz-card-com\.ua {
	background-image: url(/images/background-ua.png);
}
#iz-card-co\.zw {
	background-image: url(/images/background-zw.png);
}
#iz-card-co\.th {
	background-image: url(/images/background-th.png);
}
#iz-card-mx {
	background-image: url(/images/background-mx.png);
}
#iz-card-gr {
	background-image: url(/images/background-gr.png);
}
#iz-card-sv {
	background-image: url(/images/background-sv.png);
}
#iz-card-ng {
	background-image: url(/images/background-ng.png);
}
#iz-card-do {
	background-image: url(/images/background-do.png);
}
#iz-card-com\.ar {
	background-image: url(/images/background-ar.png);
}
#iz-card-kz {
	background-image: url(/images/background-kz.png);
}

#question {
	display: table;
	width: 952px;
	height: 220px;
	text-align: center;
	color: #000;
}

#question h1 {
	display: table-cell;
	margin: 0;
	padding: 0 100px;
	font-size: 3em;
	line-height: 1.1;
	vertical-align: middle;
}

#results {
	position: absolute;
	overflow: hidden;
	top: 250px;
	left: 206px;
	width: 540px;
	height: 490px;
}

.result {
	float: left;
	width: 50%;
	height: 100%;
	text-align: center;
}

.result .bar-wrap {
	position: relative;
	height: 400px;
	margin-bottom: 10px;
}

.result .bar-wrap .bar {
	position: absolute;
	left: 60px;
	bottom: 0;
	width: 150px;
	padding: 8px 5px;
	font-size: 3em;
	font-weight: 600;
	color: #fff;
	background-color: #b1ca54;
}

.result.green .bar-wrap .bar {
	background-color: #b1ca54;
}

.result.blue .bar-wrap .bar {
	background-color: #095478;
}

.result .answer{
	padding: 3px 10px 0;
	font-size: 1.5em;
	font-weight: 700;
	line-height: 1.2;
	color: #000;
}

#canvasImg {
	margin-bottom: 20px;
}
.fullHeight {
	height: 100%;
}