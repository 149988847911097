*,
*::before,
*::after {
  box-sizing: border-box; }


h1,
h2 {
  font-family: "Roboto Condensed", sans-serif;
  margin: 0 auto;
  text-align: center; }

h1 {
  font-size: 2em; }

h2 {
  font-size: 1.5em; }

p {
  margin: 0px;
  padding: 0px;
  font-size: 1em; }

a {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }
  a:hover, a:focus {
    outline: none; }

:focus {
  outline-style: none; }

img {
  max-width: 100%; }



.section {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 15px; }
  @media (min-width: 576px) {
    .section {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .section {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .section {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .section {
      max-width: 1140px; } }
  @media (min-width: 1600px) {
    .section {
      max-width: 1400px; } }
  @media (min-width: 2000px) {
    .section {
      max-width: 1600px; } }
  .section.section--analysis {
    display: flex;
    flex-direction: column; }
  .section .analysis {
    justify-content: space-between;
    max-width: 100%; }
    .section .analysis .analysis__title {
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      border: 1px solid #070E1E;
      padding:5px 10px;}
      .section .analysis .analysis__title .analysis__title__info {
        height: 24px;
        width: auto;
        margin-right: 15px; }
      .section .analysis .analysis__title .analysis__title__text {
        display: flex;
        justify-content: flex-start;
        flex-direction: column; 
		font-size: 14px;}
        .section .analysis .analysis__title .analysis__title__text .analysis__title__text__first {
          font-weight: bold;
          padding-bottom: 5px; }
        .section .analysis .analysis__title .analysis__title__text .analysis__title__text__red {
          padding-bottom: 5px; 
		  color: red; }
    .section .analysis .analysis__controls {
      display: flex;
      padding: 0px 0; }
      .section .analysis .analysis__controls .analysis__controls__select {
        display: flex;
        flex-direction: column;
        padding-right: 25px; }
        .section .analysis .analysis__controls .analysis__controls__select label {
          color: #A7AAAF;
          font-size: 14px;
		  margin:5px;}
        .section .analysis .analysis__controls .analysis__controls__select .analysis__controls__select__options {
          align-self: flex-end;
          font-weight: bold;
          font-size: 16px;
          padding: 2px 15px; }
      .section .analysis .analysis__controls .analysis__controls__cta {
        display: inline-flex;
        align-self: flex-end;
        background-color: #A7AAAF;
        color: #fff;
        font-weight: bold;
        font-size: 16px;
        padding: 6px 15px;
        cursor: pointer;	}
        .section .analysis .analysis__controls .analysis__controls__cta img {
          height: 20px;
          width: auto;
          margin-right: 15px; }
  .section .bubles {
    position: relative;
    align-items: center;
    max-width: 100%; }
    .section .bubles .bubles__map {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      top: 50px;
      right: -50px; }
      .section .bubles .bubles__map .bubles__map__number {
        width: 100%;
        padding: 10px;
        margin-top: 10px;
        background-color: #F9F9F9;
        text-align: center;
        font-weight: bold;
        font-size: 23px; }
    .section .bubles .bubles__container {
      width: 100%;
      max-width: 1100px;
      min-height: 450px;
      margin: 0 auto;
      position: relative; }
      .section .bubles .bubles__container::before {
        position: absolute;
        content: '';
        display: block;
        background-color: rgba(219, 219, 219, 0.15);
        width: 100%;
        height: 100%;
        max-width: 1000px;
        border-top-left-radius: 500px;
        border-top-right-radius: 500px;
        left: 0;
        right: 0;
        margin: 0 auto; }
    .section .bubles .bubles__item {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      -webkit-transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out; }
      .section .bubles .bubles__item.active {
        opacity: 1; }
      .section .bubles .bubles__item .bubles__item__circle {
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; }
        .section .bubles .bubles__item .bubles__item__circle .bubles__item__circle__title,
        .section .bubles .bubles__item .bubles__item__circle .bubles__item__circle__subtitle {
          color: #fff;
          font-weight: bold; }
        .section .bubles .bubles__item .bubles__item__circle.bubles__item__circle--0 .bubles__item__circle__title, .section .bubles .bubles__item .bubles__item__circle.bubles__item__circle--1 .bubles__item__circle__title {
          font-size: 39px; }
        .section .bubles .bubles__item .bubles__item__circle.bubles__item__circle--0 .bubles__item__circle__subtitle, .section .bubles .bubles__item .bubles__item__circle.bubles__item__circle--1 .bubles__item__circle__subtitle {
          font-size: 19px; }
        .section .bubles .bubles__item .bubles__item__circle.bubles__item__circle--2 .bubles__item__circle__title {
          font-size: 26px; }
        .section .bubles .bubles__item .bubles__item__circle.bubles__item__circle--2 .bubles__item__circle__subtitle {
          font-size: 13px; }
        .section .bubles .bubles__item .bubles__item__circle.bubles__item__circle--3 .bubles__item__circle__title {
          font-size: 27px; }
        .section .bubles .bubles__item .bubles__item__circle.bubles__item__circle--3 .bubles__item__circle__subtitle {
          font-size: 10px; }
        .section .bubles .bubles__item .bubles__item__circle.bubles__item__circle--4 .bubles__item__circle__title {
          font-size: 23px; }
        .section .bubles .bubles__item .bubles__item__circle.bubles__item__circle--4 .bubles__item__circle__subtitle {
          font-size: 10px; }
        .section .bubles .bubles__item .bubles__item__circle.bubles__item__circle--5 .bubles__item__circle__title {
          font-size: 19px; }
        .section .bubles .bubles__item .bubles__item__circle.bubles__item__circle--5 .bubles__item__circle__subtitle {
          font-size: 10px; }
        .section .bubles .bubles__item .bubles__item__circle.bubles__item__circle--6 .bubles__item__circle__title {
          font-size: 17px; }
        .section .bubles .bubles__item .bubles__item__circle.bubles__item__circle--6 .bubles__item__circle__subtitle {
          font-size: 10px; }
        .section .bubles .bubles__item .bubles__item__circle.bubles__item__circle--7 .bubles__item__circle__title {
          font-size: 15px; }
        .section .bubles .bubles__item .bubles__item__circle.bubles__item__circle--7 .bubles__item__circle__subtitle {
          font-size: 10px; }
        .section .bubles .bubles__item .bubles__item__circle.bubles__item__circle--9 .bubles__item__circle__title {
          font-size: 14px; }
        .section .bubles .bubles__item .bubles__item__circle.bubles__item__circle--9 .bubles__item__circle__subtitle {
          font-size: 10px; }
      .section .bubles .bubles__item .bubles__item__details {
        position: absolute;
        display: none;
        flex-direction: column;
        font-size: 10px;
        background-color: rgba(219, 219, 219, 1);
        width: 200px;
        min-height: 150px;
        padding: 10px;
        visibility: hidden;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        border-radius: 6px;
        -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.3);
        -ms-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.3);
        box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.3); }
        .section .bubles .bubles__item .bubles__item__details .bubles__item__details__rows {
          flex-direction: column;
          display: flex;
		  color:white;
          flex: 1; }
          .section .bubles .bubles__item .bubles__item__details .bubles__item__details__rows span {
            display: block; }
            .section .bubles .bubles__item .bubles__item__details .bubles__item__details__rows span + span {
              padding-top: 5px; }
            .section .bubles .bubles__item .bubles__item__details .bubles__item__details__rows span:last-of-type {
              margin-top: auto; }
          .section .bubles .bubles__item .bubles__item__details .bubles__item__details__rows b {
            font-size: 12px; }
        .section .bubles .bubles__item .bubles__item__details.active {
          display: flex;
          visibility: visible; }
        .section .bubles .bubles__item .bubles__item__details .bubles__item__details__line {
          position: absolute;
          background-color: #DBDBDB; }
        .section .bubles .bubles__item .bubles__item__details .bubles__item__details__buble {
          position: absolute;
          height: 5px;
          width: 5px;
          background-color: #DBDBDB;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%; }
        .section .bubles .bubles__item .bubles__item__details.bubles__item__details--top .bubles__item__details__line {
          width: 1px;
          height: 60px;
          left: 0;
          right: 0;
          margin: auto;
          bottom: -60px; }
        .section .bubles .bubles__item .bubles__item__details.bubles__item__details--top .bubles__item__details__buble {
          bottom: -60px;
          left: 0;
          right: 0;
          margin: auto; }
        .section .bubles .bubles__item .bubles__item__details.bubles__item__details--bottom .bubles__item__details__line {
          width: 1px;
          height: 60px;
          left: 0;
          right: 0;
          margin: auto;
          top: -60px; }
        .section .bubles .bubles__item .bubles__item__details.bubles__item__details--bottom .bubles__item__details__buble {
          top: -60px;
          left: 0;
          right: 0;
          margin: auto; }
        .section .bubles .bubles__item .bubles__item__details.bubles__item__details--right .bubles__item__details__line {
          width: 60px;
          height: 1px;
          left: -60px;
          top: calc(50% - 1px); }
        .section .bubles .bubles__item .bubles__item__details.bubles__item__details--right .bubles__item__details__buble {
          top: calc(50% - 3px);
          left: -60px; }
        .section .bubles .bubles__item .bubles__item__details.bubles__item__details--left .bubles__item__details__line {
          width: 60px;
          height: 1px;
          right: -60px;
          top: calc(50% - 1px); }
        .section .bubles .bubles__item .bubles__item__details.bubles__item__details--left .bubles__item__details__buble {
          top: calc(50% - 3px);
          right: -60px; }
