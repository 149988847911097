.flag-icon-background {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}
.flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1em;
}
.flag-icon:before {
  content: "\00a0";
}
.flag-icon.flag-icon-squared {
  width: 1em;
}
.flag-icon-en {
  background-image: url(../images/flags/4x3/un.svg);
}
.flag-icon-en.flag-icon-squared {
  background-image: url(../images/flags/1x1/un.svg);
}
.flag-icon-admin\.questia\.eu {
  background-image: url(../images/flags/4x3/un.svg);
}
.flag-icon-admin\.questia\eu.flag-icon-squared {
  background-image: url(../images/flags/1x1/un.svg);
}
.flag-icon-admin\.questia\.com\.gh {
  background-image: url(../images/flags/4x3/gh.svg);
}
.flag-icon-admin\.questia\.com\.gh.flag-icon-squared {
  background-image: url(../images/flags/1x1/gh.svg);
}
.flag-icon-admin\.questia\.co\.id {
  background-image: url(../images/flags/4x3/id.svg);
}
.flag-icon-admin\.questia\.co\.id.flag-icon-squared {
  background-image: url(../images/flags/1x1/id.svg);
}
.flag-icon-admin\.questia\.ro {
  background-image: url(../images/flags/4x3/ro.svg);
}
.flag-icon-admin\.questia\.ro.flag-icon-squared {
  background-image: url(../images/flags/1x1/ro.svg);
}
.flag-icon-admin\.questia\.gr {
  background-image: url(../images/flags/4x3/gr.svg);
}
.flag-icon-admin\.questia\.gr.flag-icon-squared {
  background-image: url(../images/flags/1x1/gr.svg);
}
.flag-icon-admin\.questia\.co\.th {
  background-image: url(../images/flags/4x3/th.svg);
}
.flag-icon-admin\.questia\.co\.th.flag-icon-squared {
  background-image: url(../images/flags/1x1/th.svg);
}
.flag-icon-admin\.qalaisyn\.kz {
  background-image: url(../images/flags/4x3/kz.svg);
}
.flag-icon-admin\.qalaisyn\.kz.flag-icon-squared {
  background-image: url(../images/flags/1x1/kz.svg);
}

.flag-icon-questiaopinions\.net {
  background-image: url(../images/flags/4x3/xk.svg);
}
.flag-icon-questiaopinions\.net.flag-icon-squared {
  background-image: url(../images/flags/1x1/xk.svg);
}

.flag-icon-admin\.questia\.md {
  background-image: url(../images/flags/4x3/md.svg);
}
.flag-icon-admin\.questia\.md.flag-icon-squared {
  background-image: url(../images/flags/1x1/md.svg);
}

.flag-icon-admin\.questia\.ph {
  background-image: url(../images/flags/4x3/ph.svg);
}
.flag-icon-admin\.questia\.ph.flag-icon-squared {
  background-image: url(../images/flags/1x1/ph.svg);
}